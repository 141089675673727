import React from 'react'
import { Link } from 'gatsby'
import { Box, Flex, Text, SimpleGrid, Center, Image } from '@chakra-ui/react'

import ResponsiveBlock from '~components/shared/responsive-block'
import { FadeUpBox } from '~components/shared/custom-animation'

import logo from '~images/logos/Breach_Red_B.svg'

const Header = ({ BgImage, name, descTitle, desc }) => {
    return (
        <Box>
            <Box h="80vh" position="relative">
                <Box
                    position="absolute"
                    top={0}
                    width="100%"
                    height="100%"
                    overflow="hidden"
                >
                    <BgImage />
                </Box>
                <Box position="relative" top={0} bottom={0} w="100%">
                    <ResponsiveBlock wide>
                        <Flex
                            justify={['center', 'center', 'flex-start']}
                            py={5}
                            whileHover={{}}
                        >
                            <Link to="/" w="auto">
                                <Image
                                    src={logo}
                                    boxSize={'45px'}
                                    // filter="saturate(0) brightness(400%)"
                                />
                            </Link>
                        </Flex>
                    </ResponsiveBlock>
                </Box>
                <Center position="relative" w="100%" h="75%">
                    <FadeUpBox>
                        <Text
                            color="white"
                            textShadow="1px 1px 1px black"
                            fontSize={['50px', '80px']}
                            fontWeight="800"
                            align="center"
                        >
                            {name}
                        </Text>
                    </FadeUpBox>
                </Center>
            </Box>
            <ResponsiveBlock>
                <SimpleGrid columns={[1, 1, 2]} gap={8} mt={20} mb={20}>
                    <Text fontSize={['42px', '54px']} mb={[8, 0]}>
                        {descTitle}
                    </Text>
                    <Text fontSize="16px" lineHeight="2">
                        {desc}
                    </Text>
                </SimpleGrid>
            </ResponsiveBlock>
        </Box>
    )
}

export default Header
