import React from 'react'
import {
    Center,
    Flex,
    Box,
    Image,
    Text,
    SimpleGrid,
    Link,
    Button,
} from '@chakra-ui/react'

import ResponsiveBlock from '~components/shared/responsive-block'
import { FadeIn } from '~components/shared/custom-animation'

const Content = ({ image, highlightColor, tagline, summary, impact }) => {
    return (
        <Box>
            <ResponsiveBlock mb={20}>
                <Box mb={24}>
                    <FadeIn>
                        <Text
                            align="center"
                            pb={20}
                            fontSize={['45px', '50px']}
                            mt={'100px'}
                        >
                            {tagline}
                        </Text>

                        <SimpleGrid columns={[1, 1, 2]}>
                            <Flex justify="center" pr={['0px', '0px', '50px']}>
                                <Image src={image} h="50vh" pb={[20, 20, 0]} />
                            </Flex>
                            <Center h="100%">
                                <Text lineHeight={2}>{summary}</Text>
                            </Center>
                        </SimpleGrid>
                    </FadeIn>
                </Box>
                <FadeIn>
                    <Text
                        align="center"
                        fontSize="50px"
                        pb={[20, 0]}
                        mt={['80px', '50px']}
                    >
                        Our Impact
                    </Text>
                </FadeIn>
                <Box>
                    {impact.map((step, i) => (
                        <>
                            <FadeIn key={i}>
                                <Box
                                    position="relative"
                                    fontSize="35px"
                                    pl="20px"
                                    _after={{
                                        position: 'absolute',
                                        fontSize: '200px',
                                        fontWeight: '900',
                                        zIndex: -1,
                                        top: 0,
                                        left: i > 0 ? -6 : 0,
                                        content: `'${i + 1}'`,
                                        lineHeight: '100px',
                                        color: highlightColor,
                                        pl: '25px',
                                    }}
                                >
                                    {step.header}
                                    <Text fontSize="15px" pl="100px">
                                        {step.body}
                                    </Text>
                                </Box>
                            </FadeIn>
                            {i < 2 && <Box h="120px" />}
                        </>
                    ))}
                </Box>
            </ResponsiveBlock>
            <Box align="center" mb={20}>
                <Link href="/projects" _focus={{ outline: 'none' }}>
                    <Button>View more projects</Button>
                </Link>
            </Box>
        </Box>
    )
}

export default Content
