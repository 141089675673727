import React from 'react'
import { Box } from '@chakra-ui/react'

import SEO from '~components/shared/SEO'
import Header from '~components/casestudy/common/header'
import BackgroundImage from '~components/casestudy/mallard-bay-cs/background-image'
import Product from '~components/casestudy/mallard-bay-cs/product'
import Content from '~components/casestudy/common/content'

import SEOImage from '~images/socials/mb-social.jpg'
import MallardBayContentImage from '~images/backgrounds/mb-cs-deer.jpg'

const MallardBay = () => {
    return (
        <>
            <SEO
                title="Mallard Bay"
                description="Breach Software helped bring Mallard Bay from MVP to a production-ready tool for hunters and fishers."
                image={SEOImage}
                article
            />
            <Header
                BgImage={BackgroundImage}
                name="Mallard Bay"
                descTitle="A New Experience"
                desc="Sportsman book faster with Mallard Bay.  Mallard Bay delivers the world's 
                best experience for booking a guided hunt or fishing charter. But in order to do 
                that, they needed top notch software to create customer loyalty and a frictionless 
                user experience."
            />
            <Product />
            <Content
                image={MallardBayContentImage}
                tagline="Sportsmen book faster with Mallard Bay"
                summary={`The outdoors industry has been overdue for disruption for years.  Mallard Bay
                is a startup that is here to fix that.  Mallard Bay lives at the intersection of 
                technology and outdoors, bringing a modern look and feel to the antiquated process of 
                booking hunting or fishing experiences.  Based on data from the Bureau of Economic Analysis,
                outdoor recreation  is a nearly $500 billion industry.  But the amount of friction
                in booking experiences is incredibly high.  Mallard Bay hired Breach Software to 
                build their product and bring life to this industry-disrupting idea.  Our goal was to
                create a functional platform that was not only aesthetic and engaging, but also so easy
                that users would never want to use a different option again.`}
                impact={[
                    {
                        header: 'Design',
                        body: `We helped meticulously craft a brand image for Mallard Bay's 
                        digital presence that felt appealing and easy to use for all ages and 
                        technological abilities.  We worked alongside the internal team
                        to redesign several portions of the main site, as well as the
                        admin utility dashboard for outfitters.  Our design scheme was built
                        to frame and highlight the outfitters and listings, while giving users
                        the information they need right away to make a purchasing decision.  This
                        resulted in a high quality, professional-looking site and dashboard that
                        is not only beautiful, but highly usable as well.`,
                    },
                    {
                        header: 'Development',
                        body: `Breach worked as the primary developer for Mallard Bay's frontend, 
                        alongside the internal team.  Mallard Bay's feature list is massive 
                        in scope, providing value at all levels to their customers.  Breach enabled
                        Mallard Bay to produce features far more quickly than before, building out
                        a fully functioning app at lightspeed.  We helped their team more efficiently
                        iterate on user feedback so that the founding team could focus on
                        building an incredible product rather than waiting on development.`,
                    },
                    {
                        header: 'Data',
                        body: `Our team worked with Mallard Bay to make their platform more connected 
                        than ever before.  For Mallard Bay's customers to get the most out of their
                        platform, they needed to see their data in a usable format.  We helped by
                        building a user-friendly dashboard so that outfitters can see their bookings
                        and revenue in real-time.  We integrated with several APIs and third-parties to
                        bring the most value as possible to each customer.`,
                    },
                ]}
                highlightColor="#f2d0b8"
            />
        </>
    )
}

export default MallardBay
