import React from 'react'
import { Box, Image, Flex } from '@chakra-ui/react'

import { FadeIn } from '../../shared/custom-animation'

import MBProductMobile from '~images/backgrounds/mb-product-mobile.png'
import MBProduct from '~images/backgrounds/mb-product-desktop.png'

const Product = () => {
    return (
        <Box>
            <Flex
                background={'#f2d0b8'}
                height={'auto'}
                style={{
                    clipPath: 'polygon(0 0, 100% 6%, 100% 100%, 0 94%)',
                }}
                mb={20}
                flexDirection={['column', 'column', 'column', 'row']}
            >
                <Flex
                    w="100%"
                    mt="5%"
                    mb="7%"
                    align={'center'}
                    flexDirection={['column', 'column', 'row', 'row']}
                    justifyContent={['center', 'center', 'space-evenly']}
                >
                    <FadeIn>
                        <Image
                            src={MBProduct}
                            maxHeight={[
                                '0px',
                                '0px',
                                '400px',
                                '500px',
                                '600px',
                            ]}
                            display={['none', 'none', 'initial', 'initial']}
                        />
                    </FadeIn>
                    <Box pt={20} pb={[20, 20, 0, 0]}>
                        <FadeIn>
                            <Image
                                src={MBProductMobile}
                                maxHeight={[
                                    '500px',
                                    '500px',
                                    '400px',
                                    '500px',
                                    '700px',
                                ]}
                            />
                        </FadeIn>
                    </Box>
                </Flex>
            </Flex>
        </Box>
    )
}

export default Product

//#f2d0b8
//clipPath: 'polygon(0 0, 100% 5%, 100% 100%, 0 95%)'
